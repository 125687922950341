import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
// import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
// import useSettings from 'src/hooks/useSettings';
import { BackendURL } from '../constants';
import axios from 'axios';
import Cookie from 'js-cookie';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

// const isValidToken = (token) => {
//   if (!token) {
//     return false;
//   }

//   const decoded = jwtDecode(token);
//   const currentTime = Date.now() / 1000;

//   return decoded.exp > currentTime;
// };

const setSession = (token) => {
  if (token) {
    // localStorage.setItem('token', token);
    Cookie.set('token', token, { path: '/', expires: 7 });
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // localStorage.removeItem('token');
    Cookie.remove('token', { path: '/' });
    window.localStorage.removeItem('user');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'UPDATE': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  updateImage: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  // const { saveSettings } = useSettings();

  const login = async (email, password) => {
    let config22 = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'responseType': 'json',
        'Content-Type': 'application/json;charset=utf-8',
      }
    }
    const response = await axios.post(BackendURL+'/login', { email, password }, config22);
    // const response = await axios.post('/api/account/login', { email, password });
    const token = response.data.token;
    let user = response.data.user;
    window.localStorage.setItem('user', JSON.stringify(response.data.user));
    // console.log(token);
    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateImage = async (userData, newImageURl) => {

    const user = {
      ...userData,
      avatar: newImageURl
    };

    window.localStorage.setItem('user', JSON.stringify(user));
    dispatch({
      type: 'UPDATE',
      payload: {
        user
      }
    });
  };

  const register = async (firstName, lastName, email) => {
    // Current time we use the one in the JWTRegister
    let config22 = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }
    // const test = await axios.get(BackendURL+'/api/Auth/testconnection', config22);
    // console.log(test);
    const response = await axios.post(BackendURL+'/api/Auth/register', {
      firstName,
      lastName,
      email
    }, config22);
    window.localStorage.setItem('Register_email', email);
    // console.log(response);
    const { user } = response.data.data;
    // window.localStorage.setItem('token', token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      setInterval(async () => {
        try{
          // let token = Cookie.get('token');
          let res = await axios.get(BackendURL+'/user/checkauth');
          if(res.status === 401){
            logout();
            return false;
          }
        }catch(err){
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      }, 3*60*1000);
      try {
        const token = Cookie.get('token');
        if (token) {
          setSession(token);
          await axios.get(BackendURL+'/user/checkauth');
          // const response = await axios.get('/api/account/me');
          const user = JSON.parse(window.localStorage.getItem('user'));
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        updateImage,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;