import React from 'react';

const LogoDark = (props) => {
  return (
    <img
      alt="LogoDark"
      src="/static/logo.svg"
      style={{ maxWidth: '200px' }}
      {...props}
    />
  );
}

export default LogoDark;
